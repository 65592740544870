<template lang="pug">
.FcwButtonGroup(:style="style")
  .FcwButtonGroup__option(
    v-for="option in options"
    :key="option.value"
    :ref="refs.set"
    tabindex="0"
    :class="getOptionClasses(option)"
    @keypress.enter="handleChange(option)"
  )
    input.FcwButtonGroup__input(
      :id="option.value"
      :value="option.value"
      tabindex="-1"
      name="option"
      type="radio"
      :checked="selectedValue === option.value"
      @change="handleChange(option)"
    )
    label.FcwButtonGroup__label(:for="option.value") {{ option.label }}
  .FcwButtonGroup__selection(:class="selectionClasses")
</template>

<style lang="stylus">
.FcwButtonGroup
  display inline-flex
  gap rem(6)
  position relative
  padding rem(6)
  background-color var(--color--neutral--light-3)
  border-radius rem(50)

.FcwButtonGroup__option
  position relative
  z-index 1
  border-radius rem(25)
  outline 0 solid transparent
  overflow hidden
  transition background-color 0.2s ease, outline 0.2s ease

  &[tabindex]
    border-radius rem(25)

  &--selected
    outline 2px solid transparent

    .FcwButtonGroup__label
      color var(--color--neutral--light-5)

  &--selectedServer
    background var(--color--primary)

  &:focus:not(&--selected),
  &:hover:not(&--selected)
    background-color var(--color--neutral--light-2)

.FcwButtonGroup__input
  appearance none

.FcwButtonGroup__label
  cursor pointer
  padding rem(4) rem(16)
  user-select none
  transition color 0.2s ease
  display inline-block

.FcwButtonGroup__selection
  z-index 0
  user-select none
  position absolute
  background var(--color--primary)
  left 0
  border-radius rem(24)
  height rem(32)
  width var(--FcwButtonGroup--width)
  top 50%
  transform var(--FcwButtonGroup--transform)

  &--animate
    transition transform 0.2s ease, width 0.2s ease
</style>

<script setup lang="ts">
import { genSize } from '@fifteen/design-system-vue';

export interface Option {
  value: string;
  label: string;
}

export interface FcwButtonGroupProps {
  /**
   * Selected option
   */
  modelValue?: Option['value'];
  /**
   * Options of the button group
   */
  options?: Option[];
}

const props = withDefaults(defineProps<FcwButtonGroupProps>(), {
  modelValue: undefined,
  options: () => [],
});

const isMounted = useMounted();
const selectedValue = useVModelProxy({ props });

const refs = useTemplateRefsList<HTMLDivElement>();
const currentOptionElement = computed(
  () =>
    refs.value[
      props.options.map(({ value }) => value).indexOf(selectedValue.value ?? '')
    ]
);
const animateSelection = ref(false);

const style = ref<Style>({});

function handleChange(option: Option): void {
  selectedValue.value = option.value;
}

const { width: currentElementWidth } = useElementBounding(currentOptionElement);

watchImmediate(
  [selectedValue, currentElementWidth],
  () =>
    (style.value = {
      '--FcwButtonGroup--width': genSize(currentElementWidth.value ?? 0),
      '--FcwButtonGroup--transform': `translate(${genSize(
        currentOptionElement.value?.offsetLeft ?? 0
      )}, -50%)`,
    })
);

watchOnce(
  selectedValue,
  () => {
    setTimeout(() => {
      animateSelection.value = true;
    }, 200);
  },
  { immediate: true }
);

const selectionClasses = computed(() => ({
  'FcwButtonGroup__selection--animate': animateSelection.value,
}));

function getOptionClasses(option: Option): VueClasses {
  return {
    'FcwButtonGroup__option--selected': option.value === selectedValue.value,
    'FcwButtonGroup__option--selectedServer':
      option.value === selectedValue.value && !isMounted.value,
  };
}
</script>
